import React from 'react';
import { Link } from 'gatsby';
import { useTheme } from '../theming';
import ThemeToggler from './themeToggler';
import { css } from '@emotion/core';
import { bpMaxSM } from '../../lib/breakpoints';

export default () => {
  const theme = useTheme()
  return (
    <React.Fragment>
      <Link to="/blog" activeClassName="active" aria-label="View blog page">
        Blog
      </Link>
      <Link to="/about" activeClassName="active" aria-label="View about page">
        About
      </Link>
      <a href="https://www.youtube.com/channel/UC6qnEpwZZlbcMmvMcjIfQsw" rel="noreferrer" target="_blank">
        <img 
          css = {
            css`
              height: 1.5em;
              margin-bottom: 0;
              margin-right: 1rem;
              ${bpMaxSM} {
                margin-right: 0;
              }
          `} 
              src={'/images/youtube.png'} alt="Youtube" /></a>
      <ThemeToggler
        css={{}}
        toggleTheme={theme.toggleTheme}
        themeName={theme.themeName}
      />
    </React.Fragment>
  )
}
