const colors = {
  black: '#131415',
  white: '#fff',
  gray: '#fafafa',
  red: '#E74C3C',
  orange: '#fcb103',
  green: '#29B573',
}

export default colors
