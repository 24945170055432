import React from 'react';
import { css } from '@emotion/core';
import { bpMaxSM } from '../lib/breakpoints';
import SubscribeForm from './forms/subscribe';
// import { Twitter, GitHub } from './social';
// import Container from './container';
// import colors from '../lib/colors';

const Footer = ({ author, noSubscribeForm }) => (
  <footer>
    <div
      css={css`
        padding: 20px;
        background: #fcb103;
        align-items: center;
        bottom: 0;
        position: absolute;
        width: 100%;
        ${bpMaxSM} {
          padding: 20px;
        }
      `}
    >
      {!noSubscribeForm && (
        <div>
          <SubscribeForm />
          <br />
          <br />
        </div>
      )}
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: center;
        `}
      >
        <div
          css={css`
            opacity: 0.9;
            color: white;
          `}
        >
          {author && `${author} \u00A9 ${new Date().getFullYear()}`}
        </div>
        {/* <div>
          <Twitter />
          <GitHub />
        </div> */}
      </div>
    </div>
  </footer>
)

export default Footer
